<template lang="pug">
  Auth(:redirectWithLoginFailed="true")
    ModalController(ref="modalController"
      :rootHeader="rootHeader")
      div.wrap-tmp
        div.py50
          p(@click="$router.push(`/tmp/${uid}`)").block to modal page
          p(@click="$refs['modalController'].openModalWindow('tmp')").block open modal window
          p(@click="logout").block Sign Out
</template>

<style lang="scss" scoped>
.wrap-tmp {
  width: 100%;
  min-height: 100vh;
  padding-top: 48px;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/shared/Auth'
import ModalController from '@/components/shared/ModalController'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    ModalController
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      rootHeader: {
        title: 'tmp',
        left: {
          icon: 'mdi-battery-positive',
          to: '/spots'
        },
        right: {
          icon: 'mdi-account-circle',
          to: '/mypage'
        }
      }
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    logout () {
      this.signOut()
      setTimeout(() => {
        this.$router.push('/sign-in')
      }, 400)
    }
  }
}
</script>
